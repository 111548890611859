// material
import React from 'react';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { defaultTo } from '../../utils/nullable';
import useMobile from '../../hooks/useMobile';
import { HOME_PATH, SYSTEM_PAGE } from '../../utils/pageTypes';
import placeholderLogo from '../../static/placeholder/logo.png';
import placeholderLogoMobile from '../../static/placeholder/logo_mobile.png';

const LOGO_HEIGHT_DESKTOP = 100;
const LOGO_MAX_WIDTH_DESKTOP = 300;

const LogoContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
  flexGrow: 1,
  width: '100%',
  height: '100%'
}));

const LogoStyle = styled(Box)(() => ({
  minHeight: '40px',
  maxHeight: LOGO_HEIGHT_DESKTOP,
  cursor: 'pointer',
  objectFit: 'contain'
}));

export const Logo = ({ container = true, logo, mobileLogo, handleNavigationClick, rootSx, containerSx, sx }) => {
  const isMobile = useMobile();
  const mobileSrc = defaultTo(mobileLogo, logo);

  const desktopImage = defaultTo(logo, placeholderLogo);
  const mobileImage = defaultTo(mobileSrc, placeholderLogoMobile);

  const orientationImage = isMobile ? mobileImage : desktopImage;

  return container ? (
    <LogoContainer sx={{ ...containerSx }}>
      <LogoStyle
        component="img"
        alt="Logo"
        src={orientationImage}
        sx={{ ...sx }}
        onClick={() => handleNavigationClick(SYSTEM_PAGE, HOME_PATH)}
      />
    </LogoContainer>
  ) : (
    <Box sx={{ maxWidth: `${LOGO_MAX_WIDTH_DESKTOP}px`, ...rootSx }}>
      <LogoStyle
        component="img"
        alt="Logo"
        src={orientationImage}
        sx={{ ...sx }}
        onClick={() => handleNavigationClick(SYSTEM_PAGE, HOME_PATH)}
      />
    </Box>
  );
};
