import React, { useCallback, useEffect, useState } from 'react';
import { Box, Stack, Link, Typography, Divider } from '@material-ui/core';
import { compact } from 'lodash';
import { alpha, styled, useTheme } from '@material-ui/core/styles';
import { defaultTo, isInvalid } from '../../utils/nullable';
import {
  CURRENCY_SELECTOR_VIEW_POSITION,
  DEFAULT_ADDRESS_HEADING_TEXT,
  DEFAULT_EMAIL_HEADING_TEXT,
  DEFAULT_REGISTRATION_HEADING_TEXT,
  MOBILE_CONTAINER_LEFT_RIGHT_PADDING,
  MOBILE_STACK_SPACING,
  SUBSCRIPTION_VIEW_POSITION
} from '../../utils/constants';
import useMobile from '../../hooks/useMobile';
import { getSocialIcons } from '../../utils/getSocialIcons';
import { NewsletterInput } from './NewsletterInput';
import { LinkHeaderText } from './LinkHeaderText';
import { ServiceIcons } from '../checkout/CheckoutIcons';
import { Logo } from './Logo';
import { StoreCurrencyPicker } from './StoreCurrencyPicker';

const Root = styled(Box)(() => ({
  width: '100%',
  boxShadow: 'none'
}));

const InnerRoot = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  width: '100%',
  boxShadow: 'none',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.text.secondary
}));

const SectionRoot = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 1.5)
}));

const StoreInfoBox = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(5, 1.5),
  textAlign: 'center',
  bottom: 0,
  left: 0,
  right: 0
}));

const FooterInfoText = ({ sx, children }) => (
  <Typography variant="body2" sx={{ ...sx }}>
    {children}
  </Typography>
);

const LinkText = styled((props) => <Typography variant="body2" {...props} />)(() => ({
  cursor: 'pointer',
  '&:hover': { opacity: 0.72 }
}));

const MAX_COLUMN_WIDTH = 450;
const MAX_NAVIGATION_COLUMN_WIDTH = 300;

// -------------------- COMPONENTS

const StoreInformation = ({
  isMobile,
  showPaymentMethods,
  showStoreNameFooter,
  shouldCenterText,
  storeInfo,
  additionalNotes,
  colour,
  socialLinks
}) => {
  const { services, storeName } = storeInfo;
  return (
    <StoreInfoBox
      spacing={1}
      direction="column"
      sx={{ ...(shouldCenterText && { textAlign: 'center' }), ...(isMobile && { padding: 0 }) }}
    >
      {Boolean(showPaymentMethods && !isInvalid(services)) && (
        <Box sx={{ paddingBottom: (theme) => theme.spacing(2) }}>
          <ServiceIcons
            services={services}
            justifyContent={shouldCenterText ? 'center' : 'end'}
            {...(isMobile && { sx: { padding: '3px' } })}
          />
        </Box>
      )}
      {Boolean(!isInvalid(storeName) && showStoreNameFooter) && (
        <LinkHeaderText sx={{ ...(colour && { color: colour }) }}>{storeName}</LinkHeaderText>
      )}
      {!isInvalid(additionalNotes) && (
        <LinkHeaderText sx={{ ...(colour && { color: colour }) }}>{additionalNotes}</LinkHeaderText>
      )}
      {!isInvalid(socialLinks) && (
        <SocialLinks shouldCenterText={shouldCenterText} links={socialLinks} colour={colour} sx={{ pt: 1 }} />
      )}
    </StoreInfoBox>
  );
};

const BrandInformation = ({
  logo,
  showLogo,
  shouldCenterText,
  isMobile,
  title,
  description,
  colour,
  handleNavigationClick
}) => {
  const isTitleInvalid = isInvalid(title);
  const isDescriptionInvalid = isInvalid(description);

  return (
    <SectionRoot sx={{ ...(shouldCenterText && { textAlign: 'center' }), ...(isMobile && { padding: 0 }) }}>
      <Stack spacing={2} direction="column">
        {showLogo && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: shouldCenterText ? 'center' : 'start',
              paddingBottom: (theme) => theme.spacing(2)
            }}
          >
            <Logo
              logo={logo.standard}
              mobileLogo={logo.mobile}
              handleNavigationClick={handleNavigationClick}
              containerSx={{ maxWidth: '100%' }}
            />
          </Box>
        )}
        {!isTitleInvalid && (
          <LinkHeaderText sx={{ fontSize: 16, ...(colour && { color: colour }) }}>{title}</LinkHeaderText>
        )}
        {!isDescriptionInvalid && (
          <Typography variant="body1" sx={{ marginTop: 0 }}>
            {description}
          </Typography>
        )}
      </Stack>
    </SectionRoot>
  );
};

const StoreContactDetails = ({
  isMobile,
  info,
  shouldCenterText,
  addressHeadingText,
  emailHeadingText,
  registrationHeadingText,
  showAddress,
  showEmail,
  showRegistrationNo,
  colour
}) => {
  const { apartment, address, city, postCode, country, email, registrationNo } = info;
  const addressFormatted = compact([apartment, address, city, postCode, country]).join(', ');
  return (
    <Stack
      spacing={2}
      direction="column"
      sx={{
        ...(shouldCenterText && { textAlign: 'center' }),
        padding: (theme) => (isMobile ? 0 : theme.spacing(5, 1.5))
      }}
    >
      {Boolean(showAddress && !isInvalid(addressFormatted)) && (
        <Stack direction="column" spacing={1}>
          <LinkHeaderText sx={{ margin: 0, ...(colour && { color: colour }) }}>
            {!isInvalid(addressHeadingText) ? addressHeadingText : DEFAULT_ADDRESS_HEADING_TEXT}
          </LinkHeaderText>
          <FooterInfoText>{addressFormatted}</FooterInfoText>
        </Stack>
      )}
      {Boolean(showEmail && !isInvalid(email)) && (
        <Stack direction="column" spacing={1}>
          <LinkHeaderText sx={{ margin: 0, ...(colour && { color: colour }) }}>
            {!isInvalid(emailHeadingText) ? emailHeadingText : DEFAULT_EMAIL_HEADING_TEXT}
          </LinkHeaderText>
          <FooterInfoText>{email}</FooterInfoText>
        </Stack>
      )}
      {Boolean(showRegistrationNo && !isInvalid(registrationNo)) && (
        <Stack spacing={1} direction="column">
          <LinkHeaderText sx={{ margin: 0, ...(colour && { color: colour }) }}>
            {!isInvalid(registrationHeadingText) ? registrationHeadingText : DEFAULT_REGISTRATION_HEADING_TEXT}
          </LinkHeaderText>
          <FooterInfoText>{registrationNo}</FooterInfoText>
        </Stack>
      )}
    </Stack>
  );
};

const NavigationLinks = ({ shouldCenterText, isMobile, navigation, handleNavigationClick, colour }) => {
  const { name, description, links } = navigation;
  return (
    <SectionRoot
      sx={{
        ...(!isMobile && { maxWidth: MAX_NAVIGATION_COLUMN_WIDTH }),
        ...(shouldCenterText && { textAlign: 'center' }),
        ...(isMobile && { padding: 0 })
      }}
    >
      <Stack spacing={2} direction="column">
        {!isInvalid(name) && <LinkHeaderText sx={{ ...(colour && { color: colour }) }}>{name}</LinkHeaderText>}
        {!isInvalid(description) && (
          <FooterInfoText sx={{ ...(colour && { color: colour }) }}>{description}</FooterInfoText>
        )}
        <Stack spacing={isMobile ? 1 : 2} direction="column">
          {links.map((_link, index) => {
            const {
              name,
              navigation: { type, path }
            } = _link;
            return (
              <LinkText key={index} onClick={() => handleNavigationClick(type, path)}>
                {name}
              </LinkText>
            );
          })}
        </Stack>
      </Stack>
    </SectionRoot>
  );
};

const SocialLinks = ({ shouldCenterText, links, colour, sx }) => {
  return (
    <Stack direction="row" spacing={2} sx={{ ...sx, ...(shouldCenterText && { justifyContent: 'center' }) }}>
      {links.map((social, index) => {
        const { key, href } = social;
        return (
          <Link
            key={index}
            href={href}
            underline="none"
            sx={{ color: (theme) => colour || theme.palette.secondary.contrastText }}
          >
            {getSocialIcons(key)}
          </Link>
        );
      })}
    </Stack>
  );
};

// -------------------------------

const INIT_FOOTER_NAVIGATION = {
  ids: [],
  loading: false,
  data: null
};
const INIT_FOOTER_STORE_INFO = {
  ids: [],
  loading: false,
  data: null
};

const NEWSLETTER_MAX_WIDTH = 600;
const NEWSLETTER_MIN_WIDTH = 350;

export default function Footer({
  logo,
  config,
  currency,
  currencies,
  capability,
  currenciesLoading,
  handleChangeCurrency,
  handleGetNavigations,
  handleGetStoreInformation,
  handleNavigationClick,
  handleCreateCustomer
}) {
  const isMobile = useMobile();
  const [navigation, setNavigation] = useState(INIT_FOOTER_NAVIGATION);
  const [storeInfo, setStoreInfo] = useState(INIT_FOOTER_STORE_INFO);
  const { currency: currencyCapability } = capability;
  const {
    footer: {
      showLogo,
      enableNavigation,
      title,
      description,
      navigationLinkIds,
      backgroundColour,
      primaryColour: primaryColourRoot,
      textColour: textColourRoot,
      showStoreNameFooter,
      socialLinks,
      showAddress,
      showEmail,
      showRegistrationNo,
      mobileCenterText,
      showBorder,
      showBorderRadius,
      borderColour,
      borderSize: borderSizeRoot,
      borderRadius,
      switchDesktopOrientation,
      showSubscrition,
      subscription,
      addressHeadingText,
      emailHeadingText,
      registrationHeadingText,
      additionalNotes,
      showPaymentMethods
    },
    appearance,
    currency: currencyConfig
  } = config;

  const theme = useTheme();
  // Config
  const primaryColour = defaultTo(primaryColourRoot, theme.palette.secondary.contrastText);
  const textColour = defaultTo(textColourRoot, theme.palette.primary.main);
  const shouldCenterText = Boolean(isMobile && mobileCenterText);
  const borderSize = borderSizeRoot || 0;

  // Subscription
  const { position, showDivider, dividerColour } = subscription;
  const isStandardView = position === SUBSCRIPTION_VIEW_POSITION[0];
  const isNewsletterTopView = position === SUBSCRIPTION_VIEW_POSITION[1];

  // Currency
  const isCurrencyTopView = currencyConfig?.footer?.verticalPosition === CURRENCY_SELECTOR_VIEW_POSITION[1];
  const isCurrencyEnabled = currencyConfig?.footer?.enabled;

  // Misc
  const enableBrandDetails = Boolean(showLogo || !isInvalid(title) || !isInvalid(description));
  const enableContactDetails = Boolean(showAddress || showEmail || showRegistrationNo);

  const getNavigationData = useCallback(() => {
    const isNavigationReady = !navigation.loading && navigationLinkIds !== navigation.ids;
    if (isNavigationReady) {
      setNavigation({ ...INIT_FOOTER_NAVIGATION, loading: true });
      handleGetNavigations(navigationLinkIds)
        .then((_navigation) => {
          setNavigation({
            ids: navigationLinkIds,
            loading: false,
            data: _navigation
          });
        })
        .catch(() => setNavigation({ ids: navigationLinkIds, loading: false }));
    }
  }, [navigationLinkIds, navigation, handleGetNavigations]);

  const getStoreInfoData = useCallback(() => {
    const isStoreInfoReady = !storeInfo.loading && storeInfo.data === null;
    if (isStoreInfoReady) {
      setStoreInfo({ ...INIT_FOOTER_STORE_INFO, loading: true });
      handleGetStoreInformation().then((_store) => {
        setStoreInfo({
          loading: false,
          data: _store
        });
      });
    }
  }, [storeInfo, handleGetStoreInformation]);

  useEffect(() => {
    getNavigationData();
  }, [getNavigationData]);

  useEffect(() => {
    getStoreInfoData();
  }, [getStoreInfoData]);

  const common = {
    shouldCenterText,
    isMobile
  };

  const commonNewsletterProps = {
    config: {
      ...subscription,
      input: appearance?.input
    },
    shouldCenterText,
    primaryColour,
    secondaryColour: textColour,
    onCreateCustomer: handleCreateCustomer
  };

  const NewsletterInputTopView = (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: (theme) => (isMobile ? theme.spacing(0, 0, 4) : theme.spacing(0, 3, 4))
        }}
      >
        <NewsletterInput
          {...commonNewsletterProps}
          sx={{
            maxWidth: `${NEWSLETTER_MAX_WIDTH}px`,
            minWidth: `${NEWSLETTER_MIN_WIDTH}px`
          }}
        />
      </Box>
      {showDivider && (
        <Divider
          orientation="horizontal"
          sx={{
            ...(isMobile && { marginBottom: 4 }),
            ...(dividerColour && { borderColor: alpha(dividerColour, 0.24) }),
            ...(Boolean(isCurrencyTopView && isCurrencyEnabled) && { margin: 2 })
          }}
        />
      )}
    </>
  );

  const NewsletterInputBottomView = <NewsletterInput {...commonNewsletterProps} />;

  const StoreCurrencyPickerView = (
    <StoreCurrencyPicker
      capability={currencyCapability}
      currency={currency}
      options={currencies}
      loading={currenciesLoading}
      primaryColour={primaryColour}
      secondaryColour={textColour}
      config={{
        ...currencyConfig?.footer,
        input: appearance?.input
      }}
      onChangeCurrency={handleChangeCurrency}
    />
  );

  const BrandDetailsView = (
    <BrandInformation
      {...common}
      logo={logo}
      showLogo={showLogo}
      colour={primaryColour}
      title={title}
      description={description}
      handleNavigationClick={handleNavigationClick}
    />
  );

  const StoreContactDetailsView = (
    <StoreContactDetails
      {...common}
      colour={primaryColour}
      addressHeadingText={addressHeadingText}
      emailHeadingText={emailHeadingText}
      registrationHeadingText={registrationHeadingText}
      showAddress={showAddress}
      showEmail={showEmail}
      showRegistrationNo={showRegistrationNo}
      info={storeInfo.data}
    />
  );

  const StoreInformationView = (
    <StoreInformation
      {...common}
      colour={primaryColour}
      showPaymentMethods={showPaymentMethods}
      showStoreNameFooter={showStoreNameFooter}
      storeInfo={storeInfo.data}
      additionalNotes={additionalNotes}
      socialLinks={socialLinks}
    />
  );

  const DesktopView = () => (
    <>
      {Boolean(isNewsletterTopView && showSubscrition && subscription) && NewsletterInputTopView}

      {isCurrencyTopView && StoreCurrencyPickerView}

      <Stack direction={switchDesktopOrientation ? 'row-reverse' : 'row'} justifyContent="space-between">
        {Boolean(enableBrandDetails || enableContactDetails) && (
          <Stack direction="column" sx={{ ...(!isMobile && { maxWidth: MAX_COLUMN_WIDTH }) }}>
            {enableBrandDetails && BrandDetailsView}
            {Boolean(storeInfo.data && enableContactDetails) && StoreContactDetailsView}
          </Stack>
        )}
        <Stack direction="row" spacing={2} justifyContent="space-evenly" flexGrow={1}>
          {enableNavigation &&
            navigation.data?.map((data) => (
              <NavigationLinks
                key={data.id}
                {...common}
                colour={primaryColour}
                navigation={data}
                handleNavigationClick={handleNavigationClick}
              />
            ))}
        </Stack>
        {Boolean(isStandardView && showSubscrition && subscription) && (
          <SectionRoot sx={{ ...(!isMobile && { maxWidth: MAX_COLUMN_WIDTH }), ...(isMobile && { padding: 0 }) }}>
            {NewsletterInputBottomView}
          </SectionRoot>
        )}
      </Stack>

      {storeInfo.data && StoreInformationView}

      {!isCurrencyTopView && StoreCurrencyPickerView}
    </>
  );

  const MobileView = () => (
    <>
      {Boolean(isNewsletterTopView && showSubscrition && subscription) && NewsletterInputTopView}

      <Stack spacing={MOBILE_STACK_SPACING} direction="column">
        {isCurrencyTopView && StoreCurrencyPickerView}

        {Boolean(enableBrandDetails || enableContactDetails) && (
          <>
            {enableBrandDetails && BrandDetailsView}
            {Boolean(storeInfo.data && enableContactDetails) && StoreContactDetailsView}
          </>
        )}
        {enableNavigation &&
          navigation.data?.map((data) => (
            <NavigationLinks
              key={data.id}
              {...common}
              colour={primaryColour}
              navigation={data}
              handleNavigationClick={handleNavigationClick}
            />
          ))}
        {Boolean(isStandardView && showSubscrition && subscription) && NewsletterInputBottomView}
        {storeInfo.data && StoreInformationView}

        {!isCurrencyTopView && StoreCurrencyPickerView}
      </Stack>
    </>
  );

  return (
    <Root
      sx={{
        ...(showBorder && {
          // Border size
          padding: borderSize || 0,
          // Border colour
          backgroundColor: (theme) => defaultTo(borderColour, theme.palette.primary.main)
        })
      }}
    >
      <InnerRoot
        sx={{
          ...(isMobile && {
            padding: (theme) => theme.spacing(MOBILE_CONTAINER_LEFT_RIGHT_PADDING, MOBILE_CONTAINER_LEFT_RIGHT_PADDING)
          }),
          ...(!isInvalid(backgroundColour) && { backgroundColor: backgroundColour }),
          ...(!isInvalid(textColour) && { color: textColour }),
          ...(showBorder && {
            ...(showBorderRadius && { borderRadius })
          })
        }}
      >
        {isMobile ? <MobileView /> : <DesktopView />}
      </InnerRoot>
    </Root>
  );
}
