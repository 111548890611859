// material
import React from 'react';
import { Logo as LogoStyled } from '../Logo';
import { APPBAR_DESKTOP } from './util/HeaderConstant';

export const HeaderLogo = ({
  isMobile,
  enableNavigation,
  isStandardView,
  logoPosition,
  logo,
  handleNavigationClick
}) => {
  const { standard, mobile } = logo;
  const isRight = logoPosition === 'RIGHT';
  const isLeft = logoPosition === 'LEFT';
  return (
    <LogoStyled
      {...(isMobile && { container: false })}
      logo={standard}
      mobileLogo={mobile}
      handleNavigationClick={handleNavigationClick}
      rootSx={{
        ...(enableNavigation === false && { ml: '0 !important' })
      }}
      containerSx={{
        maxHeight: APPBAR_DESKTOP,
        ...(!isStandardView && { width: 'auto' }),
        ...(isLeft && { justifyContent: 'start' }),
        ...(isRight && { justifyContent: 'end' })
      }}
      sx={{ ...(isMobile && { height: '100%' }) }}
    />
  );
};
