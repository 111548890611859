// material
import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Button, Box, Divider, Stack } from '@material-ui/core';
import { getTextAlignPosition } from '../../../utils/getPositions';
import useMobile from '../../../hooks/useMobile';
import { createTabValue } from '../../../utils/pageTypes';
import { NavigationText } from './HeaderText';
import { BUTTON_NAVIGATION_VIEW, STANDARD_NAVIGATION_VIEW, TEXT_NAVIGATION_VIEW } from '../../../utils/constants';

const TabWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: theme.spacing(7),
  width: '100%',
  padding: theme.spacing(0)
}));

const TabItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  position: 'relative',
  cursor: 'pointer'
}));

const TabIndicator = styled(Box)(({ theme }) => ({
  width: '90%',
  height: 2,
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.primary.main
}));

const NavigationStyle = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: 0,
  alignItems: 'center'
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  minHeight: theme.spacing(4),
  height: theme.spacing(4)
}));

const defaultSpacing = (type) => {
  if (type === STANDARD_NAVIGATION_VIEW) {
    return 2;
  }
  return 1;
};

const dividerSpacing = (type) => {
  if (type === STANDARD_NAVIGATION_VIEW) {
    return 2;
  }
  return 1.5;
};

const NavigationItem = ({ link, config: { viewType, textColour }, activeTab, handleNavigationClick, sx }) => {
  const {
    name,
    navigation: { type, path }
  } = link;
  const tabValue = createTabValue(type, path);
  const isSelected = activeTab === tabValue;
  const button = (
    <ButtonStyled
      color="primary"
      variant={isSelected ? 'contained' : 'text'}
      onClick={() => handleNavigationClick(type, path)}
      sx={sx}
    >
      <NavigationText textColour={textColour} isActive={isSelected} {...(isSelected && { sx: { color: 'inherit' } })}>
        {name}
      </NavigationText>
    </ButtonStyled>
  );
  const standard = (
    <TabItem onClick={() => handleNavigationClick(type, path)} sx={sx}>
      <NavigationText textColour={textColour} isActive={isSelected}>
        {name}
      </NavigationText>
      {isSelected && <TabIndicator />}
    </TabItem>
  );
  const text = (
    <TabItem onClick={() => handleNavigationClick(type, path)} sx={sx}>
      <NavigationText textColour={textColour} isActive={isSelected}>
        {name}
      </NavigationText>
    </TabItem>
  );
  switch (viewType) {
    case STANDARD_NAVIGATION_VIEW:
      return standard;
    case BUTTON_NAVIGATION_VIEW:
      return button;
    case TEXT_NAVIGATION_VIEW:
      return text;
    default:
      return standard;
  }
};

const NavigationView = ({ fullWidth = true, config, isMobile, navigation, position, ...other }) => {
  const { showDivider, viewType } = config;
  return (
    !isMobile && (
      <NavigationStyle sx={{ ...(!fullWidth && { width: 'auto' }) }}>
        <TabWrapper sx={{ ...(!fullWidth && { width: 'auto' }) }}>
          <Stack
            display="inline-flex"
            spacing={showDivider ? dividerSpacing(viewType) : defaultSpacing(viewType)}
            direction="row"
            flexWrap="nowrap"
            {...(showDivider && { divider: <Divider orientation="vertical" flexItem /> })}
            sx={{
              justifyContent: getTextAlignPosition(position),
              width: '100%',
              ...(!fullWidth && { width: 'auto' })
            }}
          >
            {navigation.links.map((link) => (
              <NavigationItem key={link.id} link={link} config={config} {...other} sx={{ flexShrink: 0 }} />
            ))}
          </Stack>
        </TabWrapper>
      </NavigationStyle>
    )
  );
};

export const HeaderNavigationTab = ({ fullWidth, enabled, navigation, ...other }) => {
  const isMobile = useMobile();
  return (
    Boolean(navigation && enabled) && (
      <NavigationView fullWidth={fullWidth} navigation={navigation} isMobile={isMobile} {...other} />
    )
  );
};
