import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { alpha, styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import minusFill from '@iconify/icons-eva/minus-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import checkmark24Filled from '@iconify/icons-fluent/checkmark-24-filled';
import { useFormik, Form, FormikProvider, useField } from 'formik';
// material
import {
  Box,
  Link,
  Stack,
  Button,
  Divider,
  Typography,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
// utils
import { isEmpty, uniq, compact, head, filter, orderBy, isEqual } from 'lodash';
import { paramCase } from 'change-case';
import { getAddToCartIcon } from '../../../utils/getProductIcons';
import { getTextAlignPosition } from '../../../utils/getPositions';
import { DEFAULT_PRODUCT_COLOURS } from '../../../theme/palette';
import { PRODUCT_PAGE } from '../../../utils/pageTypes';
import useMobile from '../../../hooks/useMobile';
import { fCurrency } from '../../../utils/formatNumber';
import { MIconButton } from '../@material-extend';
import Markdown from '../Markdown';
import { getStatusLabel } from '../../../utils/getStatusLabel';
import { ColourChip } from '../ColourChip';
import { ProductVariantSelector } from './ProductVariantSelector';
import {
  MAX_DROPDOWN_WIDTH_LARGE,
  MOBILE_CONTAINER_LEFT_RIGHT_PADDING,
  MOBILE_CONTAINER_TOP_BOTTOM_PADDING,
  PRODUCTS_ITEM_DESCRIPTION_VIEW_TYPE,
  PRODUCT_FOOTER_DESCRIPTION_TITLE,
  PRODUCT_VARIANT_VIEW_TYPE
} from '../../../utils/constants';
import { defaultTo } from '../../../utils/nullable';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up(1368)]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8)
  }
}));

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    border: `1px solid ${alpha(theme.palette.border, 0.56)}`,
    padding: theme.spacing(0.5, 1, 0.5, 2.5),
    maxWidth: MAX_DROPDOWN_WIDTH_LARGE,
    width: '100%',
    boxShadow: 'none'
  }
}));

const ProductDescriptionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${alpha(theme.palette.border, 0.56)}`,
  borderRadius: theme.shape.textfieldBorderRadius,
  padding: theme.spacing(2.5, 2),
  maxWidth: MAX_DROPDOWN_WIDTH_LARGE,
  boxShadow: 'none',
  width: '100%'
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}));

const IncrementerStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 0.75),
  border: `1px solid ${alpha(theme.palette.border, 0.32)}`,
  lineHeight: 0,
  borderRadius: theme.shape.textfieldBorderRadius,
  display: 'flex',
  flexDirection: 'column',
  width: 200
}));

// ----------------------------------------------------------------------

const Incrementer = (props) => {
  const [field, , helpers] = useField(props);
  // eslint-disable-next-line react/prop-types
  const { isInfiniteStock, available } = props;
  const { value } = field;
  const { setValue } = helpers;

  const incrementQuantity = () => {
    setValue(value + 1);
  };
  const decrementQuantity = () => {
    setValue(value - 1);
  };

  return (
    <IncrementerStyle>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', color: 'text.primary' }}>
        <MIconButton size="small" color="inherit" disabled={value <= 1} onClick={decrementQuantity}>
          <Icon icon={minusFill} width={16} height={16} />
        </MIconButton>
        <Typography
          variant="body2"
          component="span"
          sx={{
            color: 'inherit',
            width: 40,
            textAlign: 'center',
            display: 'inline-block'
          }}
        >
          {value}
        </Typography>
        <MIconButton
          size="small"
          color="inherit"
          {...(!isInfiniteStock && { disabled: value >= available })}
          onClick={incrementQuantity}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </MIconButton>
      </Box>
    </IncrementerStyle>
  );
};

export default function ProductSumary({
  isWidget,
  isWideView,
  currency,
  product,
  messagingPlacements = [],
  handleProductChange = () => {},
  handleNavigationClick = () => {},
  handleIsAddedToCart = () => false,
  handleAddToCart = () => {},
  handleRemoveFromCart = () => {},
  handleBuyNow = async () => {},
  config
}) {
  const {
    showProductColours,
    showProductTag = true,
    showSku = false,
    showDescription = true,
    descriptionHeaderBorder,
    descriptionHeaderViewType,
    descriptionHeaderBackgroundColour,
    descriptionHeaderTextColour,
    showInventoryQuantity = false,
    inventoryQuantityThreshold = 0,
    showPaymentMessages = true,
    position,
    showSpacingTop,
    variantsViewType,
    descriptionTabTitle,
    showDescriptionTab = false,
    addToCartButtonEnabled,
    addToCartButtonIcon
  } = config;
  const isMobile = useMobile();
  const state = useRef(null);

  // CONFIG
  const showDescriptionInSummary = isWidget ? showDescription : Boolean(showDescription && !showDescriptionTab);
  const tagBgColour = config?.tagBackgroundColour || DEFAULT_PRODUCT_COLOURS.tagBackgroundColour;
  const tagTextColour = config?.tagTextColour || DEFAULT_PRODUCT_COLOURS.tagTextColour;
  const saleBgColour = config?.saleBackgroundColour || DEFAULT_PRODUCT_COLOURS.saleBackgroundColour;
  const saleTextColour = config?.saleTextColour || DEFAULT_PRODUCT_COLOURS.saleTextColour;
  const outOfStockBgColour = config?.outOfStockBackgroundColour || DEFAULT_PRODUCT_COLOURS.outOfStockBackgroundColour;
  const outOfStockTextColour = config?.outOfStockTextColour || DEFAULT_PRODUCT_COLOURS.outOfStockTextColour;

  const {
    id,
    name,
    description,
    image,
    resource,
    price,
    priceSale,
    status,
    isInfiniteStock,
    sku,
    variants,
    quantity,
    colours: availableColours
  } = product;
  const isProductOutOfStock = paramCase(defaultTo(status, '')) === 'out-of-stock';
  const priceSaleInMajorUnit = priceSale?.amount;

  // The job of getVariantColours / getVariantSizes is to find the colours / size for the corresponding change. This is to avoid
  // listing variant combinations that do not exist. The calculation requires all other props except the prop being returned.
  const getVariantColours = useCallback(
    () => orderBy(uniq(compact(variants.map((_variant) => _variant.colour))), [], ['desc']),
    [variants]
  );

  const getVariantSizes = useCallback(
    (colour) => {
      const filtered = colour ? filter(variants, { colour }) : variants;
      return orderBy(uniq(compact(filtered.map((_variant) => _variant.size))), [], ['desc']);
    },
    [variants]
  );

  const getProductVariant = useCallback(
    (colour, size) => {
      return variants.find(
        (_variant) =>
          paramCase(defaultTo(_variant.colour, '')) === paramCase(defaultTo(colour, '')) &&
          paramCase(defaultTo(_variant.size, '')) === paramCase(defaultTo(size, ''))
      );
    },
    [variants]
  );

  const getSelectedSku = useCallback((variant) => (variant ? variant?.sku : sku), [sku]);

  const getSelectedAvailable = useCallback((variant) => (variant ? variant?.quantity : quantity), [quantity]);

  // Note - Returns price object and not the amount. See object spec for usage
  const getSelectedPrice = useCallback((variant) => (variant ? variant?.price : price), [price]);

  const getProduct = useCallback(
    (values) => {
      const { purchaseQuantity, size, colour } = values;
      const variant = getProductVariant(colour, size);
      return {
        id,
        name,
        resource,
        image,
        isInfiniteStock,
        quantity: purchaseQuantity,
        variantId: variant?.id,
        sku: getSelectedSku(variant),
        price: getSelectedPrice(variant),
        available: getSelectedAvailable(variant),
        size: variant?.size,
        colour: variant?.colour
      };
    },
    [
      id,
      name,
      image,
      resource,
      isInfiniteStock,
      getSelectedSku,
      getSelectedPrice,
      getSelectedAvailable,
      getProductVariant
    ]
  );

  const getFormikConfig = useCallback(() => {
    const initialColour = head(getVariantColours());
    const colour = isEmpty(variants) ? null : initialColour;
    return {
      enableReinitialize: true,
      initialValues: {
        available: quantity,
        // Variant
        colour,
        size: isEmpty(variants) ? null : head(getVariantSizes(initialColour)),
        // Order quantity
        purchaseQuantity: 1
      },
      onSubmit: async (values, { setSubmitting }) => {
        try {
          setSubmitting(false);
          const orderProduct = getProduct(values);
          await handleBuyNow(orderProduct);
        } catch (error) {
          setSubmitting(false);
        }
      }
    };
  }, [variants, quantity, getProduct, getVariantColours, getVariantSizes, handleBuyNow]);

  const formik = useFormik(getFormikConfig());

  const { values, touched, errors, getFieldProps, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    if (!isEqual(state.current, values)) {
      state.current = values;
      const snapshot = getProduct(values);
      handleProductChange(snapshot);
    }
  }, [getProduct, handleProductChange, values]);

  // SELECTED VALUES
  const selectedVariant = getProductVariant(values.colour, values.size);
  const selectedSku = selectedVariant ? selectedVariant?.sku : sku;
  const selectedPriceSale = selectedVariant ? selectedVariant?.priceSale?.amount : priceSaleInMajorUnit;
  const selectedPrice = getSelectedPrice(selectedVariant);
  const selectedAvailable = getSelectedAvailable(selectedVariant);
  const isAddedToCart = handleIsAddedToCart(id, selectedVariant?.id);

  // OPTIONS
  const colours = getVariantColours();
  const sizes = getVariantSizes(values.colour);

  const quantityThresholdCheck =
    (selectedAvailable <= inventoryQuantityThreshold || inventoryQuantityThreshold === 0) && selectedAvailable > 0;
  const displayRemainingQuantity = showInventoryQuantity && quantityThresholdCheck;

  const onClickAddCart = () => {
    const orderProduct = getProduct(values);
    handleAddToCart(orderProduct);
  };

  const onClickRemoveCart = () => {
    handleRemoveFromCart(id, selectedVariant?.id);
  };

  const onChangeColour = (newColour) => {
    setFieldValue('colour', newColour);
    // Reset selected size based on available for colour
    setFieldValue('size', head(getVariantSizes(newColour)));
  };

  const ActiveCartButton = () => (
    <Button
      fullWidth
      disabled={isProductOutOfStock}
      type="button"
      variant="outlined"
      {...(addToCartButtonEnabled && { startIcon: <Icon icon={getAddToCartIcon(addToCartButtonIcon)} /> })}
      onClick={onClickAddCart}
    >
      Add To Cart
    </Button>
  );

  const InactiveCartButton = () => (
    <Button
      fullWidth
      type="button"
      variant="outlined"
      startIcon={<Icon icon={checkmark24Filled} />}
      onClick={onClickRemoveCart}
    >
      Remove From Cart
    </Button>
  );

  const ProductInfoMemo = useMemo(() => {
    const isShowingSku = Boolean(showSku && selectedSku);
    const isShowingColours = Boolean(showProductColours && !isEmpty(availableColours));
    return (
      <Stack
        spacing={1}
        direction="column"
        sx={{
          ...(position && { alignItems: getTextAlignPosition(position) }),
          ...(Boolean(isWideView && !showSpacingTop) && { paddingTop: (theme) => theme.spacing(2) })
        }}
      >
        {showProductTag && status && (
          <Box sx={{ height: '30px' }}>
            {getStatusLabel(status, {
              saleBgColour,
              tagBgColour,
              outOfStockBgColour,
              saleTextColour,
              tagTextColour,
              outOfStockTextColour
            })}
          </Box>
        )}

        {!isEmpty(name) && (
          <HeadingText
            variant="h5"
            {...(isWidget && {
              sx: {
                cursor: 'pointer'
              },
              onClick: () => {
                if (resource) {
                  handleNavigationClick(PRODUCT_PAGE, resource);
                }
              }
            })}
          >
            {name}
          </HeadingText>
        )}
        {isShowingSku && (
          <Box sx={{ ...(position && { textAlign: getTextAlignPosition(position) }) }}>
            <HeadingText variant="body1" sx={{ mb: (theme) => theme.spacing(0.5), fontWeight: 'bold' }}>
              Stock Keeping Unit
            </HeadingText>
            <Typography color="text.secondary">{selectedSku}</Typography>
          </Box>
        )}
        {isShowingColours && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: getTextAlignPosition(position) }}>
            {availableColours.map((colour, index) => (
              <ColourChip
                key={colour}
                sx={{ backgroundColor: colour, ...(Boolean(index === availableColours.length - 1) && { mr: 0 }) }}
              />
            ))}
          </Box>
        )}

        <Typography color="primary" variant="h5" sx={{ fontWeight: 'bold' }}>
          {fCurrency(selectedPrice.amount, currency)}
          {selectedPriceSale && (
            <Box component="span" sx={{ color: 'text.disabled', textDecoration: 'line-through', ml: 2 }}>
              {fCurrency(selectedPriceSale, currency)}
            </Box>
          )}
        </Typography>
        <Divider />
      </Stack>
    );
  }, [
    currency,
    isWidget,
    isWideView,
    showSpacingTop,
    name,
    resource,
    position,
    status,
    saleBgColour,
    tagBgColour,
    outOfStockBgColour,
    saleTextColour,
    tagTextColour,
    outOfStockTextColour,
    showProductTag,
    showSku,
    selectedSku,
    showProductColours,
    availableColours,
    selectedPrice.amount,
    selectedPriceSale,
    handleNavigationClick
  ]);

  const ProductDescriptionMemo = useMemo(() => {
    const getDescription = () => {
      if (descriptionHeaderViewType === PRODUCTS_ITEM_DESCRIPTION_VIEW_TYPE[1]) {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: getTextAlignPosition(position),
              width: '100%'
            }}
          >
            <AccordionStyle
              disableGutters
              elevation={0}
              sx={{
                '&.MuiAccordion-root': {
                  ...(!descriptionHeaderBorder && { border: 0 }),
                  ...(!isEmpty(descriptionHeaderBackgroundColour) && {
                    backgroundColor: descriptionHeaderBackgroundColour
                  }),
                  ...(!isEmpty(descriptionHeaderTextColour) && { color: descriptionHeaderTextColour })
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  <Icon
                    icon={arrowIosDownwardFill}
                    width={20}
                    height={20}
                    color={!isEmpty(descriptionHeaderTextColour) ? descriptionHeaderTextColour : 'inherit'}
                  />
                }
                sx={{ color: (theme) => theme.palette.primary.main, px: 0 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: !isEmpty(descriptionHeaderTextColour) ? descriptionHeaderTextColour : 'text.primary',
                    fontWeight: (theme) => theme.typography.fontWeightMedium
                  }}
                >
                  {defaultTo(descriptionTabTitle, PRODUCT_FOOTER_DESCRIPTION_TITLE)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 0, pt: (theme) => theme.spacing(3) }}>
                <Markdown children={description} />
              </AccordionDetails>
            </AccordionStyle>
          </Box>
        );
      }
      return (
        <ProductDescriptionWrapper
          sx={{
            justifyContent: getTextAlignPosition(position),
            ...(!descriptionHeaderBorder && { border: 0 }),
            ...(!descriptionHeaderBorder && isEmpty(descriptionHeaderBackgroundColour) && { p: 0 }),
            ...(!isEmpty(descriptionHeaderBackgroundColour) && {
              backgroundColor: descriptionHeaderBackgroundColour
            }),
            ...(!isEmpty(descriptionHeaderTextColour) && { color: descriptionHeaderTextColour })
          }}
        >
          <Markdown children={description} />
        </ProductDescriptionWrapper>
      );
    };
    return showDescriptionInSummary && !isEmpty(description) && getDescription();
  }, [
    position,
    descriptionHeaderBorder,
    descriptionHeaderViewType,
    descriptionHeaderBackgroundColour,
    descriptionHeaderTextColour,
    descriptionTabTitle,
    showDescriptionInSummary,
    description
  ]);

  const ProductVariantSelectors = () => {
    const isSelectView = variantsViewType === PRODUCT_VARIANT_VIEW_TYPE[1];
    if (isEmpty(colours) && isEmpty(sizes)) {
      return null;
    }
    return (
      <Stack
        spacing={2}
        sx={{
          width: '100%',
          maxWidth: MAX_DROPDOWN_WIDTH_LARGE,
          ...(position && { alignItems: getTextAlignPosition(position) })
        }}
      >
        {!isEmpty(colours) && (
          <ProductVariantSelector
            position={position}
            isSelectView={isSelectView}
            prop="colour"
            title="Colour"
            options={colours}
            form={formik}
            onChange={(_, newValue) => onChangeColour(newValue)}
          />
        )}
        {!isEmpty(sizes) && (
          <ProductVariantSelector
            position={position}
            isSelectView={isSelectView}
            prop="size"
            title="Size"
            options={sizes}
            form={formik}
            {...(!isSelectView && { sx: { mt: (theme) => theme.spacing(2) } })}
            onChange={(e, newValue) => {
              // Dropdown view
              if (e) {
                getFieldProps('size').onChange(e);
                return;
              }
              // Select view
              setFieldValue('size', newValue);
            }}
          />
        )}
      </Stack>
    );
  };

  return (
    <RootStyle
      sx={{
        ...(isMobile && {
          paddingLeft: (theme) => `${theme.spacing(MOBILE_CONTAINER_LEFT_RIGHT_PADDING)} !important`,
          paddingRight: (theme) => `${theme.spacing(MOBILE_CONTAINER_LEFT_RIGHT_PADDING)} !important`,
          padding: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING, MOBILE_CONTAINER_LEFT_RIGHT_PADDING)
        }),
        ...(isWideView && { paddingBottom: (theme) => theme.spacing(5) })
      }}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ ...(position && { alignItems: getTextAlignPosition(position) }) }}>
            {ProductInfoMemo}
            {ProductDescriptionMemo}
            <ProductVariantSelectors />

            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle1" sx={{ color: 'text.primary', mt: 0.5 }}>
                Quantity
              </Typography>
              <Incrementer name="purchaseQuantity" available={selectedAvailable} isInfiniteStock={isInfiniteStock} />
              {displayRemainingQuantity && (
                <Typography
                  variant="caption"
                  sx={{
                    mt: 1,
                    display: 'block',
                    color: 'text.secondary'
                  }}
                >
                  Available: {selectedAvailable}
                </Typography>
              )}

              <FormHelperText error>{touched.purchaseQuantity && errors.purchaseQuantity}</FormHelperText>
            </Stack>

            {!showDescription && isWidget && (
              <Box>
                <Typography
                  onClick={() => {
                    if (resource) {
                      handleNavigationClick(PRODUCT_PAGE, resource);
                    }
                  }}
                  variant="body2"
                  color="text.secondary"
                  component={Link}
                  sx={{ cursor: 'pointer' }}
                >
                  View product details
                </Typography>
              </Box>
            )}

            {Boolean(!isWidget && showPaymentMessages) &&
              messagingPlacements.map((placement, index) => (
                <Box key={index} sx={{ width: '100%', maxWidth: MAX_DROPDOWN_WIDTH_LARGE }}>
                  {placement}
                </Box>
              ))}
          </Stack>

          <Stack
            spacing={3}
            sx={{ mt: (theme) => theme.spacing(3), ...(position && { alignItems: getTextAlignPosition(position) }) }}
          >
            <Divider sx={{ width: '100%', maxWidth: MAX_DROPDOWN_WIDTH_LARGE }} />

            <Stack
              spacing={2}
              direction={{ xs: isMobile ? 'column' : 'row' }}
              sx={{
                mt: 3,
                width: '100%',
                maxWidth: MAX_DROPDOWN_WIDTH_LARGE
              }}
            >
              {isAddedToCart ? <InactiveCartButton /> : <ActiveCartButton />}

              <Button disabled={isProductOutOfStock} fullWidth type="submit" variant="contained">
                Buy Now
              </Button>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </RootStyle>
  );
}
